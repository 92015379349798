import { getPlayerStats, isReturningPlayer } from '../../../utils/playerStats';

// Analytics configuration
const isProd = window.location.hostname === 'bigbrainsports.io';
const SESSION_STORAGE_KEY = 'bbs_alumni_game_session';

function logAnalytics(type: string, data: Record<string, any>): void {
  if (!isProd) {
    console.log(`[Analytics - ${type}]`, data);
  }
}

function initSession(): void {
  if (!sessionStorage.getItem(SESSION_STORAGE_KEY)) {
    sessionStorage.setItem(SESSION_STORAGE_KEY, Date.now().toString());
  }
}

// Track game start
export function trackAlumniGameStart(): void {
  initSession();
  const isReturning = isReturningPlayer();
  logAnalytics('Game Start', { timestamp: Date.now(), isReturning });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'alumni_game_start', {
      event_category: 'Alumni Game',
      event_label: 'Game #2',
      player_type: isReturning ? 'returning' : 'new'
    });
  }
}

// Track round start
export function trackRoundStart(roundNumber: number, playerId: number): void {
  const stats = getPlayerStats()[playerId] || { attempts: 0 };
  const isRepeatPlayer = stats.attempts > 0;

  logAnalytics('Round Start', { roundNumber, playerId, isRepeatPlayer });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'alumni_round_start', {
      event_category: 'Alumni Game',
      event_label: `Round ${roundNumber}`,
      value: playerId,
      metric1: isRepeatPlayer ? 1 : 0
    });
  }
}

// Track answer submission
export function trackAnswerSubmit(
  roundNumber: number,
  playerId: number,
  answer: string,
  isCorrect: boolean,
  timeSpent: number,
  streak: number,
  score: number
): void {
  const stats = getPlayerStats()[playerId] || { attempts: 0 };
  const isRepeatPlayer = stats.attempts > 0;

  logAnalytics('Answer Submit', {
    roundNumber,
    playerId,
    answer,
    isCorrect,
    timeSpent,
    streak,
    score,
    isRepeatPlayer
  });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'alumni_answer', {
      event_category: 'Alumni Game',
      event_label: `Player ${playerId} - ${isCorrect ? 'Correct' : 'Incorrect'}`,
      value: timeSpent,
      metric1: streak,
      metric2: score,
      metric3: isRepeatPlayer ? 1 : 0
    });
  }
}

// Track game completion
export function trackAlumniGameComplete(
  score: number,
  maxStreak: number,
  correctAnswers: number,
  totalTime: number,
  totalRounds: number
): void {
  const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEY);
  
  logAnalytics('Game Complete', {
    score,
    maxStreak,
    correctAnswers,
    totalTime,
    totalRounds,
    sessionId
  });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'alumni_game_complete', {
      event_category: 'Alumni Game',
      event_label: `Score: ${score}`,
      value: score,
      metric1: maxStreak,
      metric2: correctAnswers,
      metric3: totalTime
    });
  }
}

// Track card flip
export function trackCardFlip(roundNumber: number, viewTime: number): void {
  logAnalytics('Card Flip', { roundNumber, viewTime });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'alumni_card_flip', {
      event_category: 'Alumni Game',
      event_label: `Round ${roundNumber}`,
      value: viewTime
    });
  }
}