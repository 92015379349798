export function generateShareText(score: number, streak: number, isPerfectGame: boolean): string {
  const maxScore = 500;
  const shareText = isPerfectGame
    ? `🧠🏀 Perfect ${maxScore}/${maxScore} on Big Brain Sports #5!`
    : `🧠🏀 Scored ${score}/${maxScore} on Big Brain Sports #5`;
  
  return `${shareText}\nTest your NBA IQ at bigbrainsports.io`;
}

export function isGameCompletedToday(): boolean {
  const data = localStorage.getItem('bbs_alumni_game_data');
  if (!data) return false;
  
  try {
    const { dateCompleted } = JSON.parse(data);
    const today = new Date().toISOString().split('T')[0];
    const completedDate = new Date(dateCompleted).toISOString().split('T')[0];
    return today === completedDate;
  } catch {
    return false;
  }
}

export function getCompletionPercentile(score: number): number {
  // Simulated percentile calculation - replace with actual data
  const basePercentile = (score / 500) * 100;
  return Math.min(Math.round(basePercentile), 99);
}

export async function shareToClipboard(text: string): Promise<void> {
  await navigator.clipboard.writeText(text);
}

export async function shareToSocial(text: string): Promise<void> {
  if (navigator.share) {
    await navigator.share({
      text,
      url: 'https://bigbrainsports.io'
    });
  } else {
    await shareToClipboard(text);
  }
}