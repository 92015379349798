import React from 'react';

export function Footer() {
  return (
    <footer className="mt-8 px-4 py-6 text-center text-xs text-gray-500 max-w-2xl mx-auto">
      <p>
        Please note: Big Brain Sports does not own any team, league, player, or event logos depicted within this site. 
        All sports and player images contained within this site are properties of their respective leagues, teams, 
        ownership groups and/or organizations.
      </p>
    </footer>
  );
}