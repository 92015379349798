import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TrendingUp } from 'lucide-react';
import { Player } from '../types';
import { getCompletionRate } from '../../../utils/playerStats';
import { AutocompleteInput } from '../components/AutocompleteInput';
import { D1_SCHOOLS } from '../data/d1Schools';
import '../styles/animations.css';

interface PlayerCardProps {
  player: Player;
  isRevealed: boolean;
  onSubmit: (answer: string) => void;
  disabled?: boolean;
  isCorrect?: boolean;
}

export function PlayerCard({ player, isRevealed, onSubmit, disabled, isCorrect }: PlayerCardProps) {
  const [inputValue, setInputValue] = React.useState('');
  const cardRef = React.useRef<HTMLDivElement>(null);
  const resultTextRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const completionRate = getCompletionRate(player.id);

  // Reset input when player changes
  React.useEffect(() => {
    setInputValue('');
  }, [player.id, disabled]);

  // Preload next player's images
  React.useEffect(() => {
    const preloadImage = (src: string) => {
      const img = new Image();
      img.src = src;
    };
    
    if (!isRevealed) {
      preloadImage(player.collegeImage);
    }
  }, [player.collegeImage, isRevealed]);

  React.useEffect(() => {
    if (cardRef.current) {
      cardRef.current.classList.add('card-enter');
      const cleanup = () => cardRef.current?.classList.remove('card-enter');
      cardRef.current.addEventListener('animationend', cleanup);
      return () => cardRef.current?.removeEventListener('animationend', cleanup);
    }
  }, []);

  React.useEffect(() => {
    if (isRevealed && cardRef.current) {
      cardRef.current.classList.add('card-flip');

      if (isCorrect !== undefined) {
        cardRef.current.classList.add(isCorrect ? 'correct-answer' : 'wrong-answer');

        // Add result text
        const resultText = document.createElement('div');
        resultText.className = `result-text ${isCorrect ? 'correct-text' : 'wrong-text'}`;
        resultText.textContent = isCorrect ? 'Correct!' : 'Brick!';
        cardRef.current.appendChild(resultText);
        resultTextRef.current = resultText;
      }

      const cleanup = () => {
        cardRef.current?.classList.remove('card-flip');
        cardRef.current?.classList.remove('correct-answer', 'wrong-answer');
        resultTextRef.current?.remove();
      };

      cardRef.current.addEventListener('animationend', cleanup);
      return () => cardRef.current?.removeEventListener('animationend', cleanup);
    }
  }, [isRevealed, isCorrect]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!disabled && inputValue.trim()) {
      onSubmit(inputValue);
    }
  };

  return (
    <motion.div
      ref={cardRef}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-lg overflow-hidden pb-10 relative max-w-[280px] mx-auto"
    >
      <div className="px-2.5 pt-2.5 pb-1">
        <h2 className="text-base font-bold text-gray-900 mb-0.5">{player.name}</h2>
        <p className="text-gray-600 text-xs mb-1">{player.currentTeam} • {player.position}</p>
        {isRevealed && completionRate > 0 && (
          <span className="absolute top-2.5 right-2.5 text-xs font-medium bg-gray-100 px-2 py-0.5 rounded-full">
            {completionRate}%
          </span>
        )}
      </div>

      <div className="relative">
        <AnimatePresence mode="wait" initial={false}>
          {!isRevealed ? (
            <div className="w-full aspect-[4/5] overflow-hidden bg-gray-50">
              <motion.img
                key="nba"
                initial={{ opacity: 0, scale: 1.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ 
                  duration: 0.4,
                  ease: [0.4, 0, 0.2, 1]
                }}
                src={player.nbaImage}
                alt={`${player.name} NBA headshot`}
                className="w-full h-full object-cover object-top"
                loading="eager"
              />
            </div>
          ) : (
            <div className="w-full aspect-[2/3] overflow-hidden bg-gray-50">
              <motion.img
                key="college"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, scale: 1.1 }}
                transition={{ 
                  duration: 0.4,
                  ease: [0.4, 0, 0.2, 1]
                }}
                src={player.collegeImage}
                alt={`${player.name} college photo`}
                className="w-full h-full object-cover object-center"
                loading="eager"
              />
            </div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence mode="wait">
        {!isRevealed ? (
          <motion.form
            key="input-form"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            onSubmit={handleSubmit}
            className="absolute bottom-0 left-0 right-0 px-2 pt-1 pb-2 bg-white/95 backdrop-blur-sm border-t border-gray-50 z-10"
            ref={containerRef}
          >
            <AutocompleteInput
              onSubmit={onSubmit}
              disabled={disabled}
              schools={D1_SCHOOLS}
            />
          </motion.form>
        ) : (
          <motion.div
            key="stats"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className="space-y-1.5 px-3 pt-2 absolute bottom-0 left-0 right-0 bg-white"
          >
            <div className="flex justify-between items-center">
              <span className="text-gray-600 text-sm">College:</span>
              <span className="font-medium text-gray-900 text-sm">{player.college.name}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600 text-sm">Years:</span>
              <span className="font-medium text-gray-900 text-sm">{player.college.years}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600 text-sm">PPG:</span>
              <span className="font-medium text-gray-900 text-sm">{player.college.stats.ppg}</span>
            </div>
            <div className="mt-1.5 pt-1.5 border-t border-gray-100">
              <p className="text-xs text-gray-500 leading-relaxed">
                {player.college.stats.achievements.join(' • ')}
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}