import React from 'react';
import { HelpCircle, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface HowToPlayProps {
  isOpen: boolean;
  onClose: () => void;
}

export function HowToPlay({ isOpen, onClose }: HowToPlayProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm p-4"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-xl p-6 shadow-xl max-w-lg w-full relative overflow-y-auto max-h-[90vh]"
          >
            <button
              onClick={onClose}
              className="absolute right-3 top-3 text-gray-400 hover:text-gray-600 transition-colors"
            >
              <X className="h-6 w-6" />
            </button>

            <h2 className="text-xl font-bold text-gray-900 mb-4">ABOUT: THE ALUMNI GAME</h2>
            
            <p className="text-gray-600 mb-6">
              The Alumni Game is a daily NBA trivia game powered by Big Brain Sports. Each day, test your NBA knowledge by matching current players to their college teams.
            </p>

            <div className="space-y-5 text-gray-600">
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Game Rules</h3>
                <ol className="space-y-2">
                  <li>You'll see 5 NBA players in their pro uniforms</li>
                  <li>Type in which college they attended</li>
                  <li>After you guess, see them in their college jersey with stats</li>
                  <li>Score points for correct answers and build streaks!</li>
                </ol>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Scoring</h3>
                <div className="space-y-2">
                  <li>100 points for each correct answer</li>
                  <p className="font-medium mt-3">Bonus multipliers:</p>
                  <ul className="ml-4 space-y-2">
                    <li>3 correct: 2x multiplier</li>
                    <li>5 perfect: 1000 point bonus</li>
                  </ul>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Tips</h3>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-gray-400 flex-shrink-0" />
                    Type the college's full name
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-gray-400 flex-shrink-0" />
                    Watch your streak counter for multipliers
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-gray-400 flex-shrink-0" />
                    Take your time - no penalty for thinking!
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-gray-400 flex-shrink-0" />
                    After 5 players, share your score and challenge friends
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-gray-400 flex-shrink-0" />
                    Come back tomorrow for Game #2!
                  </li>
                </ul>
              </div>

              <p className="text-center font-medium mt-4">
                Ready to test your NBA IQ? Let's play! 🏀
              </p>
              
              <div className="mt-6 pt-6 border-t border-gray-100">
                <p className="text-xs text-gray-500">
                  Note: Big Brain Sports does not own any team, league, player, or event logos depicted within this site. 
                  All sports and player images contained within this site are properties of their respective leagues, teams, 
                  ownership groups and/or organizations.
                </p>
              </div>
            </div>

            <button
              onClick={onClose}
              className="w-full mt-6 bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors font-medium"
            >
              Got it!
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}