import React from 'react';
import { motion } from 'framer-motion';
import { Share2, Copy, Twitter, Trophy, AlertTriangle } from 'lucide-react';
import Confetti from 'react-confetti';
import CountUp from 'react-countup';
import { useWindowSize } from '../hooks/useWindowSize';
import { generateShareText, isGameCompletedToday, getCompletionPercentile } from '../utils/sharing';

interface GameOverScreenProps {
  score: number;
  correctGuesses: number;
  totalPlayers: number;
  streak: number;
  onPlayAgain: () => void;
}

export function GameOverScreen({ score, correctGuesses, totalPlayers, streak, onPlayAgain }: GameOverScreenProps) {
  const [showConfetti, setShowConfetti] = React.useState(true);
  const [copied, setCopied] = React.useState(false);
  const { width, height } = useWindowSize();
  const isPerfectGame = correctGuesses === totalPlayers;
  const isCompleted = isGameCompletedToday();
  const percentile = getCompletionPercentile(score);

  const shareText = generateShareText(score, streak, isPerfectGame);

  const handleShare = async () => {
    try {
      await navigator.share({
        text: shareText,
        url: 'https://bigbrainsports.io'
      });
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareText);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Error copying:', err);
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm p-4">
      {showConfetti && isPerfectGame && (
        <Confetti width={width} height={height} recycle={false} />
      )}
      
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="w-full max-w-md bg-white rounded-xl p-6 text-center"
      >
        <div className="mb-6">
          <Trophy className="mx-auto h-16 w-16 text-yellow-500" />
          <h2 className="text-2xl font-bold mt-4">Game Over</h2>
          <div className="mt-2">
            <CountUp
              start={0}
              end={score}
              duration={1}
              className="text-4xl font-bold text-blue-600"
            />
            <span className="ml-2 text-gray-500">points</span>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex space-x-4">
            {navigator.share && (
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleShare}
                className="flex-1 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700"
              >
                <Share2 className="inline-block mr-2 h-5 w-5" />
                Share
              </motion.button>
            )}

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleCopy}
              className="flex-1 py-3 bg-gray-100 text-gray-800 rounded-lg font-medium hover:bg-gray-200 relative"
            >
              <Copy className="inline-block mr-2 h-5 w-5" />
              {copied ? 'Copied!' : 'Copy'}
            </motion.button>
          </div>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            disabled={isCompleted}
            onClick={onPlayAgain}
            className="w-full py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed relative group"
          >
            {isCompleted ? (
              <>
                <span>Come back tomorrow!</span>
                <div className="absolute -top-10 left-1/2 -translate-x-1/2 hidden group-hover:block bg-gray-900 text-white text-xs px-2 py-1 rounded whitespace-nowrap">
                  <AlertTriangle className="h-3 w-3 inline mr-1" />
                  Daily challenge already completed
                </div>
              </>
            ) : (
              'Play Again'
            )}
          </motion.button>
          
          <div className="mt-4 text-center">
            <p className="text-gray-600">
              You scored better than <span className="font-bold text-gray-900">{percentile}%</span> of players
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}