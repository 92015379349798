import React from 'react';
import { motion } from 'framer-motion';
import { Banner } from './components/Banner';
import { PlayerCard } from './components/PlayerCard';
import { GameOverScreen } from '../../components/GameOverScreen';
import { saveGameCompletion } from './utils/localStorage';
import { updatePlayerStats } from '../../utils/playerStats';
import { Footer } from '../../components/Footer';
import { TiktokIcon } from '../../components/TiktokIcon';
import { getSequentialPlayers, GAME_LENGTH } from './data/players';
import {
  trackAlumniGameStart,
  trackRoundStart,
  trackAnswerSubmit,
  trackAlumniGameComplete,
  trackCardFlip
} from './utils/analytics';

export function AlumniGame() {
  const handlePlayAgain = () => window.location.reload();

  const players = React.useMemo(() => getSequentialPlayers(), []);
  const [gameState, setGameState] = React.useState({
    currentRound: 0,
    score: 0,
    streak: 0,
    guesses: [] as boolean[],
    lastGuessCorrect: null as boolean | null
  });
  const currentPlayer = players[gameState.currentRound];
  const [isRevealed, setIsRevealed] = React.useState(false);
  const startTime = React.useRef(Date.now());
  const cardViewStartTime = React.useRef(Date.now());
  const maxStreak = React.useRef(0);

  React.useEffect(() => {
    trackAlumniGameStart();
  }, []);
  
  React.useEffect(() => {
    if (currentPlayer) {
      trackRoundStart(gameState.currentRound + 1, currentPlayer.id);
    }
    cardViewStartTime.current = Date.now();
  }, [gameState.currentRound, currentPlayer]);
  
  React.useEffect(() => {
    maxStreak.current = Math.max(maxStreak.current, gameState.streak);
  }, [gameState.streak]);

  const handleGuess = (answer: string) => {
    const timeSpent = Date.now() - startTime.current;
    const normalizedGuess = answer.toLowerCase().trim();
    const isCorrect = currentPlayer.college.acceptedAnswers.includes(normalizedGuess);
    updatePlayerStats(currentPlayer.id, isCorrect);
    const newStreak = isCorrect ? gameState.streak + 1 : 0;
    
    trackAnswerSubmit(
      gameState.currentRound + 1,
      currentPlayer.id,
      normalizedGuess,
      isCorrect,
      timeSpent,
      gameState.streak,
      gameState.score
    );
    
    setGameState(prev => ({
      ...prev,
      guesses: [...prev.guesses, isCorrect],
      streak: newStreak,
      lastGuessCorrect: isCorrect,
      score: prev.score + (isCorrect ? 100 : 0)
    }));
    setIsRevealed(true);

    const viewTime = Date.now() - cardViewStartTime.current;
    trackCardFlip(gameState.currentRound + 1, viewTime);
  };

  const handleContinue = () => {
    if (gameState.currentRound < players.length - 1) {
      const nextRound = gameState.currentRound + 1;
      setGameState(prev => ({
        ...prev,
        currentRound: nextRound
      }));
      setIsRevealed(false);
      startTime.current = Date.now();
    } else {
      const totalTime = Date.now() - startTime.current;
      const finalScore = gameState.score;
      
      trackAlumniGameComplete(
        finalScore,
        maxStreak.current,
        gameState.guesses.filter(Boolean).length,
        totalTime,
        players.length
      );
      
      // Save game completion data
      saveGameCompletion(finalScore);
      
      // Move to game over state
      setGameState(prev => ({
        ...prev,
        currentRound: players.length
      }));
    }
  };
  
  if (gameState.currentRound >= players.length) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 relative">
        <GameOverScreen
          score={gameState.score}
          correctGuesses={gameState.guesses.filter(Boolean).length}
          totalPlayers={players.length}
          streak={maxStreak.current}
          onPlayAgain={handlePlayAgain}
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-4 pt-2">
      <div className="max-w-md mx-auto">
        <Banner
          currentRound={gameState.currentRound}
          score={gameState.score}
          streak={gameState.streak}
        />
        
        <PlayerCard
          player={currentPlayer}
          isRevealed={isRevealed}
          isCorrect={gameState.lastGuessCorrect}
          onSubmit={handleGuess}
          disabled={isRevealed}
        />

        {isRevealed && (
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onClick={handleContinue}
            className="mx-auto mt-4 px-8 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 shadow-lg"
          >
            {gameState.currentRound < players.length - 1 ? 'Next Player' : 'Finish Game'}
          </motion.button>
        )}

        <div className="mt-8 text-center space-y-4">
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <a
              href="https://www.tiktok.com/@mobibojoe"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-gray-900 transition-colors inline-flex items-center gap-2"
            >
              Created by @mobibojoe <TiktokIcon className="h-4 w-4" />
            </a>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <a
              href="https://www.mobibojoe.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 transition-colors font-medium"
            >
              Subscribe to my newsletter →
            </a>
          </motion.div>
        </div>
      </div>
      <Footer />
    </div>
  );
}