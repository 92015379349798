import React from 'react';
import { Search, X } from 'lucide-react';
import { D1_SCHOOLS } from '../data/d1Schools';

interface AutocompleteInputProps {
  onSubmit: (value: string) => void;
  disabled?: boolean;
}

export function AutocompleteInput({ onSubmit, disabled }: AutocompleteInputProps) {
  const [inputValue, setInputValue] = React.useState('');
  const [suggestions, setSuggestions] = React.useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [isOpen, setIsOpen] = React.useState(false);

  const updateSuggestions = React.useCallback((value: string) => {
    const input = value.trim().toLowerCase();
    
    if (input.length < 2) {
      setSuggestions([]);
      setIsOpen(false);
      return;
    }

    const matches = D1_SCHOOLS
      .filter(school => school.toLowerCase().includes(input))
      .sort((a, b) => {
        const aLower = a.toLowerCase();
        const bLower = b.toLowerCase();
        
        // Exact matches first
        if (aLower === input) return -1;
        if (bLower === input) return 1;
        
        // Then matches at start of name
        if (aLower.startsWith(input) && !bLower.startsWith(input)) return -1;
        if (bLower.startsWith(input) && !aLower.startsWith(input)) return 1;
        
        // Then alphabetical order
        return a.localeCompare(b);
      });

    setSuggestions(matches);
    setIsOpen(matches.length > 0);
  }, []);

  React.useEffect(() => {
    updateSuggestions(inputValue);
  }, [inputValue, updateSuggestions]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => prev < suggestions.length - 1 ? prev + 1 : prev);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          handleSelect(suggestions[selectedIndex]);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
    }
  };

  const handleSelect = (value: string) => {
    setInputValue(value);
    setIsOpen(false);
    onSubmit(value);
  };

  const clearInput = () => {
    setInputValue('');
    setSuggestions([]);
    setIsOpen(false);
    setSelectedIndex(-1);
  };

  return (
    <div className="relative">
      <div className="relative">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsOpen(true)}
          placeholder="Enter college name..."
          disabled={disabled}
          autoComplete="off"
          spellCheck="false"
          className="w-full px-4 py-2.5 pl-9 pr-8 text-base rounded-lg border border-gray-200 focus:border-blue-300 outline-none transition-colors placeholder:text-gray-400 bg-white"
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
        {inputValue && (
          <button
            onClick={clearInput}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-gray-400 hover:text-gray-600 transition-colors"
          >
            <X className="h-4 w-4" />
          </button>
        )}
      </div>

      {inputValue.length >= 2 && suggestions.length > 0 && isOpen && (
        <div 
          className="
            absolute z-50 w-full mt-1
            max-h-[200px] overflow-y-auto overscroll-contain
            bg-white/95 backdrop-blur-sm
            shadow-lg rounded-lg border border-gray-100
            touch-action-pan-y
            scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent
            -webkit-overflow-scrolling-touch
          "
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#E5E7EB transparent'
          }}
        >
          {suggestions.map((suggestion, index) => (
            <button
              key={suggestion}
              onClick={() => handleSelect(suggestion)}
              className={`
                w-full px-4 py-3 text-left text-sm
                transition-colors
                hover:bg-gray-50/75
                active:bg-gray-100/75
                ${
                index === selectedIndex ? 'bg-blue-50 text-blue-600 font-medium' : 'text-gray-700'
                }
              `}
            >
              {suggestion}
            </button>
          ))}
        </div>
      )}

      <button
        onClick={() => suggestions.includes(inputValue) && onSubmit(inputValue)}
        disabled={!suggestions.includes(inputValue) || disabled}
        className={`
          w-full mt-2 px-4 py-3 rounded-lg font-medium 
          transition-colors
          focus:outline-none focus:ring-2 focus:ring-blue-500
          ${suggestions.includes(inputValue)
            ? 'bg-blue-600 hover:bg-blue-700 text-white'
            : 'bg-gray-100 text-gray-400 cursor-not-allowed'}
        `}
      >
        Submit Guess
      </button>
    </div>
  );
}