interface PlayerStats {
  attempts: number;
  correct: number;
  lastPlayed: string;
}

const STATS_KEY = 'bbs_player_stats';
const LAST_PLAYED_KEY = 'bbs_last_played_date';

export function getPlayerStats(): Record<number, PlayerStats> {
  try {
    const stats = localStorage.getItem(STATS_KEY);
    return stats ? JSON.parse(stats) : {};
  } catch {
    return {};
  }
}

export function isReturningPlayer(): boolean {
  const lastPlayed = localStorage.getItem(LAST_PLAYED_KEY);
  const today = new Date().toISOString().split('T')[0];
  return lastPlayed !== null && lastPlayed !== today;
}

export function updatePlayerStats(playerId: number, isCorrect: boolean): void {
  const stats = getPlayerStats();
  if (!stats[playerId]) {
    stats[playerId] = { attempts: 0, correct: 0, lastPlayed: '' };
  }
  
  stats[playerId].attempts++;
  if (isCorrect) {
    stats[playerId].correct++;
  }
  stats[playerId].lastPlayed = new Date().toISOString().split('T')[0];
  
  localStorage.setItem(STATS_KEY, JSON.stringify(stats));
  localStorage.setItem(LAST_PLAYED_KEY, new Date().toISOString().split('T')[0]);
}

export function getCompletionRate(playerId: number): number {
  const stats = getPlayerStats();
  const playerStats = stats[playerId];
  
  if (!playerStats || playerStats.attempts === 0) {
    return 0;
  }
  
  return Math.round((playerStats.correct / playerStats.attempts) * 100);
}