import React from 'react';
import { motion } from 'framer-motion';
import { HelpCircle, Trophy } from 'lucide-react';
import { HowToPlay } from '../../../components/HowToPlay';
import '../styles/animations.css';
import { GAME_LENGTH } from '../data/players';

interface BannerProps {
  onBack?: () => void;
  currentRound: number;
  score: number;
  streak: number;
}

export function Banner({ onBack, currentRound, score, streak }: BannerProps) {
  const [showHowToPlay, setShowHowToPlay] = React.useState(false);
  const scoreRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (streak >= 3 && scoreRef.current) {
      scoreRef.current.classList.add('streak-milestone');
      const cleanup = () => scoreRef.current?.classList.remove('streak-milestone');
      scoreRef.current.addEventListener('animationend', cleanup);
      return () => scoreRef.current?.removeEventListener('animationend', cleanup);
    }
  }, [streak]);

  return (
    <div className="px-4 pt-6 pb-4 md:pt-8 md:pb-6">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center"
      >
        <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
          Big Brain Sports
        </h1>
        
        <div className="flex items-center justify-center gap-2 mt-3">
          <h2 className="text-xl font-bold text-orange-500">
            Alumni Game #5
          </h2>
          <button
            onClick={() => setShowHowToPlay(true)}
            className="text-gray-400 hover:text-gray-600 transition-colors"
            aria-label="How to play"
          >
            <HelpCircle className="h-5 w-5" />
          </button>
        </div>
        
        <span className="inline-flex items-center gap-1 text-sm text-blue-600 mt-2">
          <Trophy className="h-3.5 w-3.5" />
          Daily Challenge
        </span>
      </motion.div>

      <div className="w-full max-w-sm mx-auto grid grid-cols-3 gap-6 mt-4">
        <div className="flex flex-col items-center">
          <p className="text-xs text-gray-500 uppercase tracking-wider font-space mb-1 whitespace-nowrap">Round</p>
          <p className="text-lg md:text-xl font-bold text-orange-600">{currentRound + 1}/{GAME_LENGTH}</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-xs text-gray-500 uppercase tracking-wider font-space mb-1 whitespace-nowrap">Score</p>
          <p className="text-lg md:text-xl font-bold text-orange-600">{score}</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-xs text-gray-500 uppercase tracking-wider font-space mb-1 whitespace-nowrap">Streak</p>
          <p className="text-lg md:text-xl font-bold text-orange-600">{streak}</p>
        </div>
      </div>
      
      <HowToPlay isOpen={showHowToPlay} onClose={() => setShowHowToPlay(false)} />
    </div>
  );
}