import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AlumniGame } from './games/alumni-game/AlumniGame';

function App() {
  return (
    <BrowserRouter>
      <AlumniGame />
    </BrowserRouter>
  );
}

export default App;