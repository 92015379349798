const STORAGE_PREFIX = 'bbs_alumni_game_';

interface GameData {
  completed: boolean;
  score: number;
  dateCompleted: string;
}

export function saveGameCompletion(score: number): void {
  const gameData: GameData = {
    completed: true,
    score,
    dateCompleted: new Date().toISOString()
  };
  
  try {
    localStorage.setItem(`${STORAGE_PREFIX}data`, JSON.stringify(gameData));
  } catch (err) {
    console.error('Error saving game completion:', err);
  }
}

export function getGameData(): GameData | null {
  const data = localStorage.getItem(`${STORAGE_PREFIX}data`);
  if (!data) return null;
  
  try {
    return JSON.parse(data);
  } catch {
    return null;
  }
}

export function isGameCompletedToday(): boolean {
  const data = getGameData();
  if (!data) return false;
  
  const today = new Date().toISOString().split('T')[0];
  const completedDate = new Date(data.dateCompleted).toISOString().split('T')[0];
  
  return today === completedDate;
}

export function getCompletionPercentile(score: number): number {
  // Simulated percentile calculation - replace with actual data
  const basePercentile = (score / 500) * 100;
  return Math.min(Math.round(basePercentile), 99);
}